import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-btn-list',
  templateUrl: './btn-list.component.html',
  styleUrls: ['./btn-list.component.scss']
})
export class BtnListComponent implements OnInit, OnChanges {

  @Input() target: string = '';
  @Input() action: string = '';
  @Input() type_id: string = '';

  public route: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let target = this.target.split('/');
    this.route = ['/', ...target, this.action].filter(item => item);
  }
}
