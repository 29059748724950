import { Injectable } from "@angular/core";
import { JwtAuthGuard } from "@info-click/jwt-auth";
import { SettingService } from "./shared/services/setting.service";
import { AuthApiService } from "./domains/auth/auth-api.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class InitService {
  constructor(
    private auth: JwtAuthGuard,
    private settingService: SettingService,
    private translate: TranslateService,
    private authService: AuthApiService
  ) {}

  private setLang() {
    const browserLang: string = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|it/) ? browserLang : "en");
    // this.translate.use("it");
  }

  init() {
    this.setLang();

    return new Promise<void>((resolve) => {
      let token = this.auth.getToken();
      if (!token) {
        resolve();
        return;
      }
      this.authService.me().subscribe({
        next: () => {
          this.authService.refresh().subscribe({
            next: () => {
              this.settingService.loadSettings().subscribe({
                next: () => {
                  resolve();
                },
                error: () => {
                  resolve();
                },
              });
            },
            error: () => {
              this.auth.unsetUser();
              this.auth.unsetToken();
              resolve();
            },
          });
        },
        error: () => {
          this.auth.unsetUser();
          this.auth.unsetToken();
          resolve();
        },
      });
    });
  }
}
