import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FiltersDrawerComponent} from './filters-drawer/filters-drawer.component';
import {NgbCollapseModule} from "@ng-bootstrap/ng-bootstrap";
import {FiltersDrawerBtnComponent} from './filters-drawer-btn/filters-drawer-btn.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    FiltersDrawerComponent,
    FiltersDrawerBtnComponent
  ],
  imports: [
    CommonModule,
    NgbCollapseModule,
    TranslateModule
  ],
  exports: [
    FiltersDrawerComponent,
    FiltersDrawerBtnComponent
  ]
})
export class FiltersDrawerModule {
}
