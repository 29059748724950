import { APP_INITIALIZER, NgModule } from "@angular/core";
import { PageTitleModule } from "./components/page-title/page-title.module";
import { SessionModule } from "./libs/session/session.module";
import { JwtAuthModule } from "@info-click/jwt-auth";
import { ApiClientModule } from "@info-click/api-client";
import { environment } from "environments/environment";
import { User } from "./domains/user/user";
import { RemoteValidationModule } from "@info-click/remote-validation";
import { SocketIoModule } from "ngx-socket-io";
import { CacheModules } from "@info-click/cache";
import { SettingDomainModule } from "./domains/setting/setting-domain-module";
import { InitService } from "./init.service";
import { IndexQueryStringModule } from "@info-click/index-query-string";
import { ToastrSubmitModule } from "@info-click/toastr-submit";
import { RouteFilter } from "./shared/services/route-filter.service";
import { LockScreenModule } from "./pages/lock-screen/lock-screen.module";
import { AttributeLangService } from "./shared/services/attribute-lang.service";
import { CompanyDomainModule } from "./domains/company/company-domain.module";
import { CompanyModule } from "./pages/company/company.module";
import { CompanyInterceptorModule } from "./domains/company/company-interceptor.module";

@NgModule({
  declarations: [],
  imports: [
    PageTitleModule,
    JwtAuthModule.forRoot({
      userType: User,
      defaultHost: environment.apiHost,
    }),
    ApiClientModule.forRoot({
      host: window.location.protocol + "//" + environment.apiHost,
    }),
    RemoteValidationModule.forRoot({}),
    SocketIoModule.forRoot({
      url: window.location.origin,
      options: { transports: ["websocket"] },
    }),
    SessionModule,
    CacheModules,
    SettingDomainModule,
    IndexQueryStringModule,
    ToastrSubmitModule,
    LockScreenModule,
    CompanyDomainModule,
    CompanyModule,
    CompanyInterceptorModule
  ],
  exports: [LockScreenModule],
  providers: [
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: InitService) => (): Promise<any> => service.init(),
      deps: [InitService],
      multi: true,
    },
    RouteFilter,
    AttributeLangService
  ],
})
export class InfoClickModule {}
