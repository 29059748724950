import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import { LockScreenService } from "./lock-screen.service";
import { Observable, throwError } from "rxjs";
import { environment } from "environments/environment";

@Injectable()
export class LockScreenInterceptor implements HttpInterceptor {
  constructor(private service: LockScreenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => {
        this.service.sessionAlive();
      }),
      catchError((err) => {
        if (
          err.status === 401 &&
          err.url &&
          err.url.indexOf("/api/auth/login") === -1 &&
          err.url
            .replace(/^http(s)?:\/\//i, "")
            .indexOf(environment.apiHost) === 0
        ) {
          this.service.sessionExpired();
        }
        return throwError(() => err);
      })
    );
  }
}
