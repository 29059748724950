import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-table-index-action",
  templateUrl: "./table-index-action.component.html",
  styleUrls: ["./table-index-action.component.scss"],
})
export class TableIndexActionComponent implements OnInit {
  @Input("item-id") itemId: string = "";
  @Input() routerLink: string[] = [];
  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
