import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-table-index",
  templateUrl: "./table-index.component.html",
  styleUrls: ["./table-index.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableIndexComponent implements OnInit, OnChanges {
  @Input("current-page") currentPage: number = 1;
  @Input("last-page") lastPage: number = 1;
  @Input("total") total: number = 1;
  @Input("range") range: number = 9;
  @Input("pagination") pagination: boolean = true;

  @Output("change-page") changePage: EventEmitter<number> =
    new EventEmitter<number>();

  public start: number;
  public end: number;
  public pagesArray: number[] = [];
  public disabled: boolean;

  public totalElement: string = "{{ total }} elements";

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cdr.markForCheck();
  }

  setCurrent(page: number) {
    if (this.currentPage === page) {
      return;
    }
    this.currentPage = Math.min(page, this.total);
    this.changePage.emit(this.currentPage);
    this.cdr.markForCheck();
  }

  private refreshPages() {
    if (this.currentPage > this.lastPage) {
      this.setCurrent(this.lastPage);
      return;
    }

    let array = [];
    let current = this.currentPage || 1;
    let pages = this.lastPage || 1;

    let start = Math.max(1, current - Math.floor(this.range / 2));
    let end = Math.min(pages + 1, start + this.range);

    if (end - start < this.range) {
      start = Math.max(1, end - this.range);
    }
    for (let i = start; i < end; i++) {
      array.push(i);
    }

    this.start = start;
    this.end = end;
    this.pagesArray = array;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshPages();
    this.cdr.markForCheck();
  }
}
