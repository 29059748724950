import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { JwtAuthGuard } from "@info-click/jwt-auth";
import { Observable } from "rxjs";

@Injectable()
export class AuthorizationMiddleware implements CanActivate, CanActivateChild {
  constructor(private router: Router, private auth: JwtAuthGuard) {
    //
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !route.data.hasOwnProperty("permission") ||
      this.auth.user().hasPermission(route.data.permission)
    ) {
      return true;
    }
    this.router.navigate(["/", "errors", "403"], { skipLocationChange: true });
    return false;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.canActivate(childRoute, state);
    return true;
  }
}
