import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Directive({
  selector: "[debounceKeyup]",
})
export class DebounceKeyupDirective {
  @Input() delay: number = 500;
  @Output() debounceKeyup = new EventEmitter();
  private value = "";

  private timeout: any;

  constructor() {}

  @HostListener("keyup", ["$event"])
  keyUpEvent(event: KeyboardEvent) {
    this.detectChange(event);
  }

  private detectChange(event: KeyboardEvent) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let input = event.target as HTMLInputElement;
      if (
        this.value != input.value ||
        event.key === "Delete" ||
        event.key === "Backspace"
      ) {
        this.debounceKeyup.emit(event);
        this.value = input.value;
      }
    }, this.delay);
  }

  ngOnInit() {}
}
