import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BtnAddComponent } from "./btn-add.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  exports: [BtnAddComponent],
  declarations: [BtnAddComponent],
  imports: [CommonModule, RouterModule, TranslateModule],
})
export class BtnAddModule {}
