import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CompanyIndexComponent } from "./company-index/company-index.component";
import { CompanyFormComponent } from "./company-form/company-form.component";

const routes: Routes = [
  {
    path: "",
    component: CompanyIndexComponent,
    data: {
      title: "Companies",
      permission: 'companies.*'
    },
  },
  {
    path: "create",
    component: CompanyFormComponent,
    data: {
      title: "Create company",
      method: "create",
      permission: 'companies.create'
    },
  },
  {
    path: ":id",
    component: CompanyFormComponent,
    data: {
      title: "View company",
      method: "view",
      permission: 'companies.read'
    },
  },
  {
    path: ":id/edit",
    component: CompanyFormComponent,
    data: {
      title: "Edit company",
      method: "edit",
      permission: 'companies.update'
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule {}
