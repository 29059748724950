import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageTitleComponent} from './page-title.component';
import {PageTitleDirective} from "./page-title.directive";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  exports: [
    PageTitleComponent,
    PageTitleDirective
  ],
  declarations: [
    PageTitleComponent,
    PageTitleDirective
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class PageTitleModule {
}
