import { Routes } from "@angular/router";
import {
  INFO_CLICK_FULL_PAGE_ROUTES,
  INFO_CLICK_ROUTES,
} from "app/info-click/config/routes";

export const APP_ROUTES: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  ...INFO_CLICK_ROUTES,
  {
    path: "logs",
    loadChildren: () =>
      import("../pages/log/log.module").then((m) => m.LogModule),
  },
  {
    path: "languages",
    loadChildren: () =>
      import("../pages/language/language.module").then((m) => m.LanguageModule),
  },
  {
    path: "categories",
    loadChildren: () =>
      import("../pages/category/category.module").then((m) => m.CategoryModule),
  },
  {
    path: "manufacturers",
    loadChildren: () =>
      import("../pages/manufacturer/manufacturer.module").then(
        (m) => m.ManufacturerModule
      ),
  },
  {
    path: "lines",
    loadChildren: () =>
      import("../pages/line/line.module").then((m) => m.LineModule),
  },
  {
    path: "seasons",
    loadChildren: () =>
      import("../pages/season/season.module").then((m) => m.SeasonModule),
  },
  {
    path: "product-types",
    loadChildren: () =>
      import("../pages/product-type/product-type.module").then(
        (m) => m.ProductTypeModule
      ),
  },
  {
    path: "products",
    loadChildren: () =>
      import("../pages/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "price-lists",
    loadChildren: () =>
      import("../pages/price-list/price-list.module").then(
        (m) => m.PriceListModule
      ),
  },
  {
    path: "product-attributes",
    loadChildren: () =>
      import("../pages/product-attribute/product-attribute.module").then(
        (m) => m.ProductAttributeModule
      ),
  },
  {
    path: "channels",
    loadChildren: () =>
      import("../pages/channel/channel.module").then((m) => m.ChannelModule),
  },
  {
    path: "product-features",
    loadChildren: () =>
      import("../pages/product-feature/product-feature.module").then(
        (m) => m.ProductFeatureModule
      ),
  },
  {
    path: "markets",
    loadChildren: () =>
      import("../pages/market/market.module").then((m) => m.MarketModule),
  },
  {
    path: "syncs",
    loadChildren: () =>
      import("../pages/sync/sync.module").then((m) => m.SyncModule),
  },
  {
    path: "discounts",
    loadChildren: () =>
      import("../pages/discount/discount.module").then((m) => m.DiscountModule),
  },
  {
    path: "connectors",
    loadChildren: () =>
      import("../pages/connector/connector.module").then(
        (m) => m.ConnectorModule
      ),
  },
];

export const APP_FULL_PAGE_ROUTES: Routes = [...INFO_CLICK_FULL_PAGE_ROUTES];
