import { Injectable } from "@angular/core";
import { ApiClient } from "@info-click/api-client";
import { CacheService } from "@info-click/cache";
import { Socket } from "ngx-socket-io";

@Injectable()
export class SettingRepository {
  private uri = "settings";

  constructor(
    private api: ApiClient,
    private cache: CacheService,
    private socket: Socket
  ) {
    this.socket.fromEvent(`model-saved.${this.uri}`).subscribe(() => {
      this.cache.forget(`${this.uri}.search.*`);
    });
  }

  search(query?: object) {
    let observer = this.api.get(this.uri, query || {});
    return this.cache.putObservable(
      this.uri + ".search." + JSON.stringify(query),
      () => {
        return observer;
      }
    );
  }

  find(id: string) {
    return this.api.get(this.uri + "/" + id);
  }

  save(item: { [key: string]: string | string[] }) {
    return this.api.put(this.uri, item);
  }

  delete(id: string) {
    return this.api.delete(this.uri + "/" + id);
  }
}
