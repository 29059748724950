import { Injectable } from "@angular/core";
import { JwtAuthGuard } from "@info-click/jwt-auth";
import { RouteInfo } from "app/shared/vertical-menu/vertical-menu.metadata";

@Injectable()
export class RouteFilter {
  constructor(private auth: JwtAuthGuard) {}

  public filter(routes: RouteInfo[]): RouteInfo[] {
    let results: RouteInfo[] = [];

    for (let routeInfo of routes) {
      if (
        routeInfo.permission &&
        !this.auth.user().hasPermission(routeInfo.permission)
      ) {
        continue;
      }

      let routeInfoClone = { ...routeInfo };

      if (routeInfoClone.submenu?.length > 0) {
        routeInfoClone.submenu = this.filter(routeInfoClone.submenu);
        if (routeInfoClone.submenu.length === 0) {
          continue;
        }
      }

      results.push(routeInfoClone);
    }

    return results;
  }
}
