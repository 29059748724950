import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class LockScreenService {
  private sessionExpiredEmitter: EventEmitter<any> = new EventEmitter<any>();
  private sessionAliveEmitter: EventEmitter<any> = new EventEmitter<any>();

  private status: "alive" | "expired" = null;

  constructor() {}

  sessionAlive() {
    if (this.status === "alive") {
      return;
    }
    this.status = "alive";
    this.sessionAliveEmitter.emit();
  }

  sessionExpired() {
    if (this.status === "expired") {
      return;
    }
    this.status = "expired";
    this.sessionExpiredEmitter.emit();
  }

  onSessionAlive(): EventEmitter<any> {
    return this.sessionAliveEmitter;
  }

  onSessionExpired(): EventEmitter<any> {
    return this.sessionExpiredEmitter;
  }
}
