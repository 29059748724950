import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BtnListComponent} from './btn-list.component';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  exports: [
    BtnListComponent
  ],
  declarations: [
    BtnListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ]
})
export class BtnListModule {
}
