import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-page-title",
  templateUrl: "./page-title.component.html",
  styleUrls: ["./page-title.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent implements OnInit, AfterViewInit {
  @ViewChild("title") title: ElementRef;
  @Input("customTitle") customTitle: string;

  public showRouteTitle: boolean = false;
  public routeTitle: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.routeTitle = this.activatedRoute.snapshot.data.title;
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.showRouteTitle = this.title.nativeElement.childNodes.length === 0;
    this.cdr.detectChanges();
  }
}
