import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { RemoteValidationService } from "@info-click/remote-validation";
import { TranslateService } from "@ngx-translate/core";
import { AUTH } from "app/config/auth";
import { AuthApiService } from "app/info-click/domains/auth/auth-api.service";
import { LockScreenService } from "../lock-screen.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-lock-screen",
  templateUrl: "./lock-screen.component.html",
  styleUrls: ["./lock-screen.component.scss"],
})
export class LockScreenComponent implements OnInit, OnDestroy {
  public show: boolean = false;
  public image: string;
  public passwordInputType: "password" | "text" = "password";
  public credentials: { email: string; password: string } = {
    email: "",
    password: "",
  };

  private sessionAliveSubscriber: Subscription;
  private sessionExpiredSubscriber: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private authApi: AuthApiService,
    private translate: TranslateService,
    private router: Router,
    public service: LockScreenService,
    public errors: RemoteValidationService
  ) {
    this.image = AUTH.images.lock;
  }

  ngOnInit(): void {
    this.sessionAliveSubscriber = this.service
      .onSessionAlive()
      .subscribe(() => {
        this.show = false;
      });
    this.sessionExpiredSubscriber = this.service
      .onSessionExpired()
      .subscribe(() => {
        this.show = true;
        setTimeout(() => {
          document.getElementById("lock-screen-email").focus();
        });
      });
  }

  ngOnDestroy(): void {
    this.sessionAliveSubscriber?.unsubscribe();
    this.sessionExpiredSubscriber?.unsubscribe();
  }

  togglePassword() {
    this.passwordInputType =
      this.passwordInputType === "password" ? "text" : "password";
    this.cdr.markForCheck();
  }

  onSubmit() {
    this.authApi.login(this.credentials).subscribe({
      complete: () => {
        this.cdr.markForCheck();

        this.authApi.me().subscribe({
          complete: () => {
            this.router.navigate(["/"]).then(() => {
              const url = this.router.url;
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([url]).then(() => {});
                  this.show = false;
                });
            });
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 401) {
              this.errors.fill({
                email: [this.translate.instant("Wrong email or password")],
              });
              this.show = true; // Keep the lock screen visible
              this.cdr.markForCheck();
            }
            this.cdr.markForCheck();
          },
        });
      },
      error: (err: HttpErrorResponse) => {
        if (err.error?.message === "Subscription expired") {
          this.errors.fill({
            email: ["Subscription expired"],
          });
          this.show = true; // Keep the lock screen visible
          this.cdr.markForCheck();
          return;
        }

        if (err.error?.message?.startsWith("Abbonamento non ancora attivo")) {
          this.errors.fill({
            email: [err.error.message],
          });
          this.show = true; // Keep the lock screen visible
          this.cdr.markForCheck();
          return;
        }

        if (err.status === 401) {
          this.errors.fill({
            password: [this.translate.instant("Wrong email or password")],
          });
          this.show = true; // Keep the lock screen visible
        }

        this.cdr.markForCheck();
      },
    });
  }
}
