import { RouteInfo } from "app/shared/vertical-menu/vertical-menu.metadata";

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: "#",
    title: "System",
    icon: "ft-monitor",
    submenu: [
      {
        path: "/users",
        title: "Users",
        permission: 'users.*',
      } as RouteInfo,
      {
        path: "/roles",
        title: "Roles",
        permission: 'roles.*',
      } as RouteInfo,
      {
        path: "/companies",
        title: "Companies",
        permission: 'companies.*',
      } as RouteInfo,
      {
        path: "/company-groups",
        title: "Company groups",
        permission: 'company-groups.*',
      } as RouteInfo,
      {
        path: "/logs",
        title: "Log",
        permission: 'logs.*',
      } as RouteInfo,
      {
        path: "",
        title: "Subscriptions",
        submenu: [
          {
            path: "/subscriptions",
            title: "Subscriptions",
            permission: 'subscriptions.*',
          } as RouteInfo,
          {
            path: "/subscription-types",
            title: "Subscription types",
            permission: 'subscriptions.*',
          } as RouteInfo,
        ],
      } as RouteInfo,
      {
        path: "/settings",
        title: "Settings",
        permission: 'settings.*',
      } as RouteInfo,
    ],
  } as RouteInfo,
];
