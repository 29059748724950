import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LockScreenService } from "./lock-screen.service";
import { LockScreenInterceptor } from "./lock-screen.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LockScreenComponent } from "./lock-screen/lock-screen.component";
import { InfoClickSharedModule } from "app/info-click/shared/info-click-shared.module";
import { AuthDomainModule } from "app/info-click/domains/auth/auth-domain.module";

@NgModule({
  declarations: [LockScreenComponent],
  imports: [CommonModule, InfoClickSharedModule, AuthDomainModule],
  providers: [
    LockScreenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LockScreenInterceptor,
      multi: true,
    },
  ],
  exports: [LockScreenComponent],
})
export class LockScreenModule {}
