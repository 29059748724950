import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";

Sentry.init({
  dsn: "https://01a8901d1ba35fd18932aa97b145b77b@sentry24.info-click.it/5",

  // integrations: [
  //   new Sentry.BrowserTracing({
  //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   }),
  //   new Sentry.Replay({
  //     maskAllText: false,
  //     blockAllMedia: false,
  //   }),
  // ],

  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
