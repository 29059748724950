<div class="table-responsive">

  <table class="table table-sm table-striped table-hover mb-0" *ngIf="total > 0">
      <ng-content></ng-content>
      <tfoot>
      <tr>
        <td colspan="99">

          <div class="d-flex">

            <div *ngIf="pagination" class="flex-grow-1">
              {{ totalElement | translate : {total: total} }}
            </div>

            <div *ngIf="pagination">

              <ul class="pagination pagination-sm mb-0">

                <li class="page-item" [ngClass]="{'disabled' : currentPage == 1 || disabled}">
                  <a class="page-link" (click)="setCurrent(currentPage - 1)" translate>
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only" translate>Previous</span>
                  </a>
                </li>

                <li *ngIf="start > 1" class="page-item">
                  <a class="page-link" (click)="setCurrent(1)">1&hellip;</a>
                </li>

                <li class="page-item" *ngFor="let page of pagesArray"
                    [ngClass]="{'active' : currentPage == page , 'disabled' : disabled }">
                  <a class="page-link" (click)="setCurrent(page)">{{ page }}</a>
                </li>

                <li *ngIf="end <= lastPage" class="page-item">
                  <a class="page-link" (click)="setCurrent(lastPage)">&hellip;{{lastPage}}</a>
                </li>

                <li class="page-item" [ngClass]="{'disabled' : currentPage == lastPage || disabled}">
                  <a class="page-link" (click)="setCurrent(currentPage + 1)">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only" translate>Next</span>
                  </a>
                </li>

              </ul>
            </div>

          </div>

        </td>
      </tr>
      </tfoot>
  </table>

  <div *ngIf="total === 0" class="text-muted text-center" translate>No item found</div>

</div>
