import { NgModule } from "@angular/core";
import { PageTitleModule } from "../components/page-title/page-title.module";
import { FiltersDrawerModule } from "../components/filters-drawer/filters-drawer.module";
import { FormsModule } from "@angular/forms";
import { BtnAddModule } from "../components/btn-add/btn-add.module";
import { BtnListModule } from "../components/btn-list/btn-list.module";
import { UserCanDirective } from "./directives/user-can.directive";
import { CardModule } from "../components/card/card.module";
import { CommonModule } from "@angular/common";
import { DebounceKeyupDirective } from "./directives/debounce-keyup.directive";
import { TableIndexModule } from "../components/table-index/table-index.module";
import { TranslateModule } from "@ngx-translate/core";
import { InputWrapperModule } from "@info-click/input-wrapper";
import { PipesModule } from "./pipes/pipes.module";
import { FormPanelModule } from "../components/form-panel/form-panel.module";
import { InputFileModule } from "app/components/input-file/input-file.module";

@NgModule({
  declarations: [UserCanDirective, DebounceKeyupDirective],
  imports: [],
  exports: [
    CommonModule,
    PageTitleModule,
    FiltersDrawerModule,
    InputWrapperModule,
    FormsModule,
    BtnAddModule,
    BtnListModule,
    UserCanDirective,
    CardModule,
    DebounceKeyupDirective,
    TableIndexModule,
    TranslateModule,
    PipesModule, 
    FormPanelModule,
    InputFileModule
  ],
  providers: [],
})
export class InfoClickSharedModule {}
