import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FiltersDrawerComponent} from "../filters-drawer/filters-drawer.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-filters-drawer-btn',
  templateUrl: './filters-drawer-btn.component.html',
  styleUrls: ['./filters-drawer-btn.component.scss']
})
export class FiltersDrawerBtnComponent implements OnInit, OnDestroy {

  @Input() drawer: FiltersDrawerComponent;
  public collapsed: boolean = true;

  private hiddenSubscription: Subscription;
  private shownSubscription: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    this.hiddenSubscription = this.drawer.hidden.subscribe(() => this.collapsed = true);
    this.shownSubscription = this.drawer.shown.subscribe(() => this.collapsed = false);
  }

  ngOnDestroy() {
    this.hiddenSubscription && this.hiddenSubscription.unsubscribe();
    this.shownSubscription && this.shownSubscription.unsubscribe();
  }

  onClick() {
    this.drawer.toggle();
  }
}
