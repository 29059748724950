import { Injectable } from "@angular/core";
import { ApiClient } from "@info-click/api-client";
import { AuthCredentials } from "./auth-credentials";
import { HttpResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { JwtAuthGuard } from "@info-click/jwt-auth";
import { AuthLoginResponse } from "./auth-login-response";
import { AuthMeResponse } from "./auth-me-response";
import { Observable, throwError } from "rxjs";
import * as moment from "moment";

interface RefreshResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
}

@Injectable()
export class AuthApiService {
  constructor(private api: ApiClient, private auth: JwtAuthGuard) {}

  private tokenRefreshDate: moment.Moment;

  public login(
    credentials: AuthCredentials
  ): Observable<HttpResponse<AuthLoginResponse>> {
    return this.api.post("auth/login", credentials).pipe(
      tap((response: HttpResponse<AuthLoginResponse>) => {
        this.setToken(response.body.access_token);
      }),
      catchError((err) => {
        this.auth.unsetToken();
        return throwError(() => err);
      })
    );
  }

  public me(): Observable<HttpResponse<AuthMeResponse>> {
    return this.api.get("auth/me").pipe(
      tap((response: HttpResponse<AuthMeResponse>) => {
        this.auth.setUser(response.body.data);
      }),
      catchError((err) => {
        this.auth.unsetUser();
        return throwError(() => err);
      })
    );
  }

  public refresh(): Observable<HttpResponse<RefreshResponse>> {
    return this.api.post("auth/refresh").pipe(
      tap((response: HttpResponse<RefreshResponse>) => {
        this.setToken(response.body.access_token);
      }),
      catchError((err) => {
        this.auth.unsetToken();
        return throwError(() => err);
      })
    );
  }

  public isTimeToRefreshToken(): boolean {
    if (!this.tokenRefreshDate) {
      return false;
    }

    // Se non è stato aggiornato da 1 minuto
    return (
      moment.duration(moment.utc().diff(this.tokenRefreshDate)).asSeconds() > 60
    );
  }

  private setToken(token: string) {
    this.auth.setToken(token);
    const tokenData = this.parseJwt(token);
    this.tokenRefreshDate = moment.utc(tokenData.nbf * 1000);
  }

  private parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
}
