import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CompanyService } from "../services/company.service";

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {
  constructor(private companyService: CompanyService) {
    //
  }

  /**
   * Scrive il token nel header della richiesta
   * @param req richiesta http
   * @param host hostname attuale
   */
  private writeTokenToHeader(req: HttpRequest<any>) {
    if(!this.companyService.getId()) {
      return req;
    }
    return req.clone({
      setHeaders: {
        'company': this.companyService.getId(),
      },
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Se c'è un token salvato, lo inserisco negli header
    let filledReq = this.writeTokenToHeader(req);
    return next.handle(filledReq);
  }
}
