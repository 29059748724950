import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TableIndexActionComponent } from "../table-index-action/table-index-action.component";
import swal, { SweetAlertResult } from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-table-index-delete-action",
  templateUrl: "./table-index-delete-action.component.html",
  styleUrls: ["./table-index-delete-action.component.scss"],
})
export class TableIndexDeleteActionComponent extends TableIndexActionComponent {
  @Input() label: string;
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
  @Input() disabled: boolean = false;

  constructor(private translate: TranslateService) {
    super();
  }

  onClick() {
    if (this.disabled) {
      return;
    }
    swal
      .fire({
        title: this.translate.instant("Warning"),
        html: "Vuoi cancellare " + this.label + "?", // this.translate.instant('Do you want to delete \'\{{ label }}\'\?', {label: this.label}),
        showCancelButton: true,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success ml-1",
        },
        buttonsStyling: false,
        confirmButtonText: this.translate.instant("Yes"),
        cancelButtonText: this.translate.instant("No"),
      })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          this.delete.emit();
        }
      })
      .catch(() => {});
  }
}
