<form (submit)="onSubmit($event)">
  <div class="d-flex">
    <div class="flex-grow-1">
      <ng-content></ng-content>
    </div>

    <div>
      <div class="form-action-bar">
        <div class="form-action-bar__container">
          <div>
            <app-form-panel-button
              type="submit"
              [disabled]="readonly"
              color="primary"
              label="Save"
              icon="ft-save"
            ></app-form-panel-button>

            <!-- <button
              type="submit"
              class="btn btn-primary text-nowrap"
              [disabled]="readonly"
              [ngbTooltip]="'Save' | translate"
            >
              <i class="ft-save"></i>
              <span class="ml-1 d-md-none" translate>Save</span>
            </button> -->
          </div>

          <div class="form-action-bar__custom">
            <ng-content select="[form-buttons]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-md-none" style="height: 10px"></div>
</form>
