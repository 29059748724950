import { EventEmitter, Injectable } from "@angular/core";
import { CompanyRepository } from "../company.repository";

@Injectable()
export class CompanyService {
  private changeEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private companyRepository: CompanyRepository) {}

  public setId(id: string) {
    localStorage.setItem("companyId", id);
    if (id) {
      this.companyRepository.latest(id).subscribe();
    }
    this.changeEmitter.emit(id);
  }

  public getId() {
    return localStorage.getItem("companyId");
  }

  public change() {
    return this.changeEmitter;
  }
}
