import { SubscriptionUser } from "../subscription-user/subscription-user";

export class User {
  public id: string = "";
  public first_name: string = "";
  public last_name: string = "";
  public full_name: string = "";
  public email: string = "";
  public avatar: string = "";
  public roles: string[] = [];
  public role_ids: string[] = [];
  public company_ids: string[] = [];
  public permissions: string[] = [];
  public extra: { color: string; internal_code: string; phone: string } = {
    color: null,
    internal_code: null,
    phone: null,
  };
  public subscription: SubscriptionUser = new SubscriptionUser();
  public subscriptions: SubscriptionUser[] = [];

  public google_id: string;
  public google_email: string;
  public google_token: any;
  public access_token: any;
  public google_calendar_id: string;

  hasPermission(permission: string) {

    // Caso di permesso con wildcard tipo 'permesso.*'
    let parts = /^([a-z_-]+)\.\*/i.exec(permission);
    if (parts) {
      // Cerco un permesso che inizia con la parte principale del wildcard
      return !!this.permissions.find(
        (action: string) => action.indexOf(parts[1] + ".") === 0
      );
    }

    // cerco il permesso specifico
    return this.permissions.indexOf(permission) !== -1;
  }
}
