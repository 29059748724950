import { HttpResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { PriceListRepository } from "app/domains/price-list/price-list.repository";
import { CompanyService } from "app/info-click/domains/company/services/company.service";
import { Socket } from "ngx-socket-io";
import { Subscription } from "rxjs";

@Component({
  selector: "app-price-list-notifier",
  templateUrl: "./price-list-notifier.component.html",
  styleUrls: ["./price-list-notifier.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceListNotifierComponent implements OnInit, OnDestroy {
  public show: boolean = false;

  private repositorySubscription: Subscription;
  private socketSubscription: Subscription;
  private companySubscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private priceListRepository: PriceListRepository,
    private socket: Socket,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.checkDefaultPriceList();

    this.socketSubscription = this.socket
      .fromEvent(`model-saved.price-lists`)
      .subscribe({
        next: () => {
          this.checkDefaultPriceList();
        },
      });

    this.socketSubscription = this.companyService.change().subscribe({
      next: () => {
        this.checkDefaultPriceList();
      },
    });
  }

  ngOnDestroy(): void {
    this.repositorySubscription?.unsubscribe();
    this.socketSubscription?.unsubscribe();
    this.companySubscription?.unsubscribe();
  }

  private checkDefaultPriceList() {
    this.repositorySubscription = this.priceListRepository
      .search({
        active: 1,
        default: 1,
      })
      .subscribe({
        next: (response: HttpResponse<any>) => {
          this.show = response.body.data.length === 0;
          this.cdr.markForCheck();
        },
      });
  }
}
