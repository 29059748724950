<div class="card" #collapse="ngbCollapse" [(ngbCollapse)]="collapsed">

  <div class="card-content">
    <div class="card-body pt-1">

      <ng-content></ng-content>

    </div>
  </div>

</div>
