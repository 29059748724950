import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormPanelComponent } from "./form-panel.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FormPanelButtonComponent } from './form-panel-button/form-panel-button.component';

@NgModule({
  declarations: [FormPanelComponent, FormPanelButtonComponent],
  exports: [FormPanelComponent, FormPanelButtonComponent],
  imports: [CommonModule, TranslateModule, NgbTooltipModule],
})
export class FormPanelModule {}
