<button
  type="button"
  class="btn mr-1"
  [class.btn-outline-secondary]="collapsed"
  [class.btn-secondary]="!collapsed"
  (click)="onClick()"
  aria-controls="collapseExample"
>
  <i *ngIf="!collapsed" class="ft-x-circle mr-1"></i>
  <i *ngIf="collapsed" class="ft-arrow-down-circle mr-1"></i>
  <span translate>Filters</span>
</button>
