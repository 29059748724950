import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableIndexComponent } from "./table-index.component";
import { TranslateModule } from "@ngx-translate/core";
import { TableIndexViewActionComponent } from "./table-index-view-action/table-index-view-action.component";
import { TableIndexEditActionComponent } from "./table-index-edit-action/table-index-edit-action.component";
import { TableIndexDeleteActionComponent } from "./table-index-delete-action/table-index-delete-action.component";
import { TableIndexActionComponent } from "./table-index-action/table-index-action.component";
import { RouterModule } from "@angular/router";
import { TableIndexDirective } from "./table-index.directive";

@NgModule({
  exports: [
    TableIndexComponent,
    TableIndexViewActionComponent,
    TableIndexEditActionComponent,
    TableIndexDeleteActionComponent,
    TableIndexDirective,
  ],
  declarations: [
    TableIndexComponent,
    TableIndexViewActionComponent,
    TableIndexEditActionComponent,
    TableIndexDeleteActionComponent,
    TableIndexActionComponent,
    TableIndexDirective,
  ],
  imports: [CommonModule, TranslateModule, RouterModule],
})
export class TableIndexModule {}
