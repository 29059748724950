import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { IndexQueryStringService } from "@info-click/index-query-string";

@Component({
  selector: "app-filters-drawer",
  templateUrl: "./filters-drawer.component.html",
  styleUrls: ["./filters-drawer.component.scss"],
})
export class FiltersDrawerComponent implements OnInit {
  @ViewChild("collapse") collapse: NgbCollapse;

  @Input() collapsed: boolean = true;
  @Input() filters: { [k: string]: any } = {};

  @Output() change: EventEmitter<void> = new EventEmitter<void>();

  @Output() shown: EventEmitter<void> = new EventEmitter<void>();
  @Output() hidden: EventEmitter<void> = new EventEmitter<void>();
  @Output() filtersChange: EventEmitter<{ [k: string]: any }> =
    new EventEmitter<{ [k: string]: any }>();
  @Output() is_collapsed: EventEmitter<boolean> = new EventEmitter<true>();

  constructor(private queryString: IndexQueryStringService) {}
  // true chiuso
  // false aperto
  ngOnInit(): void {
    this.queryString.loadQuery();
    this.filters = this.queryString.getQuery();

    this.collapsed = this.checkCollapsed(this.filters);

    this.emit();
  }

  checkCollapsed(filters) {
    // controllo per aprire o meno i filtri all'inizio
    // if (
    //   (Object.keys(filters).length === 1 &&
    //     filters.hasOwnProperty("document_type_id")) ||
    //   (Object.keys(filters).length === 1 &&
    //     filters.hasOwnProperty("contract_type_id")) || 
    //     (Object.keys(filters).length === 1 &&
    //     filters.hasOwnProperty("article_type_id")) ||
    // ) 
    if (
      Object.keys(filters).length === 1 && 
      (filters.hasOwnProperty("document_type_id") || 
      filters.hasOwnProperty("contract_type_id") || 
      filters.hasOwnProperty("article_type_id") ||
      filters.hasOwnProperty("type_id")) 
    )
    {
      return true;
    } else {
      // l'oggetto ha altre chiavi oltre a 'document_type_id' o 'contract_type_id
      return !this.queryString.hasFilters()
    }
  }
  toggle() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      this.filters = this.queryString.getExtraFilters();
      this.queryString.replaceValues(this.filters);
      this.filtersChange.emit(this.filters);
    }
    this.is_collapsed.emit(this.collapsed);

    this.emit();
  }

  private emit() {
    if (this.collapsed) {
      this.hidden.emit();
    } else {
      this.shown.emit();
    }
    this.change.emit();
  }

  onChange() {
    this.queryString.setValues(this.filters);
    this.emit();
  }
}
