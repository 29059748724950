import { NgModule } from "@angular/core";
import { NavbarCompanySelectorComponent } from "./navbar-company-selector.component";
import { CompanyDomainModule } from "app/info-click/domains/company/company-domain.module";
import { CommonModule } from "@angular/common";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  exports: [NavbarCompanySelectorComponent],
  declarations: [NavbarCompanySelectorComponent],
  imports: [CommonModule, CompanyDomainModule, NgbDropdownModule],
})
export class NavbarCompanySelectorModule {}
