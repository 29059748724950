import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-btn-add",
  templateUrl: "./btn-add.component.html",
  styleUrls: ["./btn-add.component.scss"],
})
export class BtnAddComponent implements OnInit, OnChanges {
  @Input() target: string = "";
  @Input() action: string = "create";
  @Input() type_id: string = "";

  public route: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    let target = this.target.split("/");
    this.route = ["/", ...target, this.action].filter((item) => item);
  }
}
