import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-panel-button',
  templateUrl: './form-panel-button.component.html',
  styleUrls: ['./form-panel-button.component.scss']
})
export class FormPanelButtonComponent implements OnInit {

  @Input() type: 'submit' | 'button' = 'button';
  @Input() disabled: boolean = false;
  @Input() icon: string = 'ft-help-circle';
  @Input() label: string = 'No label';
  @Input() color: string = 'secondary';

  constructor() { }

  ngOnInit(): void {
  }

}
