import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { Subscription } from "rxjs";

import { IndexQueryStringService } from "@info-click/index-query-string";
import { Pagination } from "@info-click/pagination";
import { CompanyRepository } from "app/info-click/domains/company/company.repository";
import { Company } from "app/info-click/domains/company/company";

@Component({
  selector: "app-company-index",
  templateUrl: "./company-index.component.html",
  styleUrls: ["./company-index.component.scss"],
})
export class CompanyIndexComponent implements OnInit, OnDestroy {
  private searchSubscription: Subscription;
  private deleteSubscription: Subscription;

  public pagination: Pagination<Company> = new Pagination<Company>();
  public status: "init" | "ready" = "init";

  constructor(
    private repository: CompanyRepository,
    private cdr: ChangeDetectorRef,
    private queryString: IndexQueryStringService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    this.searchSubscription && this.searchSubscription.unsubscribe();
    this.deleteSubscription && this.deleteSubscription.unsubscribe();
  }

  private loadData() {
    this.searchSubscription && this.searchSubscription.unsubscribe();
    this.searchSubscription = this.repository
      .search(this.queryString.getQuery())
      .subscribe({
        next: (response: HttpResponse<Pagination<Company>>) => {
          this.pagination = response.body;
          this.cdr.markForCheck();
          this.status = "ready";
        },
        error: () => {
          this.pagination = new Pagination<Company>();
          this.cdr.markForCheck();
          this.status = "ready";
        },
      });
  }

  onChangePage(page: number) {
    this.queryString.setValue("page", page);
    this.loadData();
  }

  onChangeFilter() {
    this.loadData();
  }

  onDeleteItem(id: string) {
    this.deleteSubscription && this.deleteSubscription.unsubscribe();
    this.deleteSubscription = this.repository.delete(id).subscribe(() => {
      this.loadData();
    });
  }
}
