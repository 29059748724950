import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { JwtAuthGuard } from "@info-click/jwt-auth";

@Directive({
  selector: "[userCan]",
})
export class UserCanDirective implements OnChanges {
  constructor(
    private auth: JwtAuthGuard,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() userCan: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    changes.userCan && this.onChangeUserCan(changes.userCan.currentValue);
  }

  private onChangeUserCan(currentValue) {
    if (currentValue === "" || this.auth.user().hasPermission(currentValue)) {
      this.show();
      return;
    }

    this.hide();
  }

  private show() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hide() {
    this.viewContainer.clear();
  }
}
