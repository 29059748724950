import { Injectable } from "@angular/core";
import { ApiClient } from "@info-click/api-client";
import { CacheService } from "@info-click/cache";
import { Socket } from "ngx-socket-io";
import { PriceList } from "./price-list";

@Injectable()
export class PriceListRepository {
  private uri = "price-lists";

  constructor(
    private api: ApiClient,
    private cache: CacheService,
    private socket: Socket
  ) {
    this.socket.fromEvent(`model-saved.${this.uri}`).subscribe(() => {
      this.cache.forget(`${this.uri}.search.*`);
    });
  }

  search(query?: object) {
    return this.api.get(this.uri, query || {});
  }

  all(query?: object) {
    return this.search(
      Object.assign({}, query || {}, {
        _all: true,
      })
    );
  }

  find(id: string) {
    return this.api.get(this.uri + "/" + id);
  }

  save(item: PriceList) {
    return item.id
      ? this.api.put(this.uri + "/" + item.id, item)
      : this.api.post(this.uri, item);
  }

  delete(id: string) {
    return this.api.delete(this.uri + "/" + id);
  }
}
