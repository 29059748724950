<div class="d-md-flex">

  <div class="flex-grow-1">
    <div class="content-header flex-grow-1">

      <span #title translate><ng-content></ng-content></span>

      <ng-container *ngIf="showRouteTitle && !customTitle">
        <span translate>{{ routeTitle }}</span>
      </ng-container>
      
      <ng-container *ngIf="customTitle">
        <span [innerHTML]="customTitle"></span>
      </ng-container>

    </div>
  </div>

  <div class="mt-md-3">
    <ng-content select="app-page-title-actions"></ng-content>
  </div>
</div>
