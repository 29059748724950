<div class="lock-screen-wrapper" *ngIf="show">
  <!--Lock Screen Starts-->
  <section id="lock-screen">
    <div class="row auth-height full-height-vh m-0">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card overflow-hidden">
          <div class="card-content">
            <div class="card-body auth-img">
              <div class="row m-0">
                <div
                  class="col-lg-6 d-lg-flex justify-content-center align-items-center d-none text-center auth-img-bg p-3"
                >
                  <img
                    [src]="image"
                    alt=""
                    class="img-fluid"
                    height="230"
                    width="280"
                  />
                </div>

                <div class="col-lg-6 col-md-12 py-3 px-4">
                  <form (submit)="onSubmit()">
                    <h4 class="card-title mb-3">Your Session is Locked</h4>
                    <div class="mb-2">
                      <input-wrapper [error]="errors.first('email')">
                        <input
                          id="lock-screen-email"
                          type="email"
                          name="email"
                          class="form-control"
                          [placeholder]="'Email' | translate"
                          [(ngModel)]="credentials.email"
                        />
                      </input-wrapper>
                    </div>
                    <div class="mb-2">
                      <input-wrapper [error]="errors.first('password')">
                        <input
                          [type]="passwordInputType"
                          id="lock-screen-password"
                          name="password"
                          class="form-control"
                          [placeholder]="'Password' | translate"
                          [(ngModel)]="credentials.password"
                        />
                        <button
                          append
                          class="btn btn-outline-secondary px-2"
                          type="button"
                          (click)="togglePassword()"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </input-wrapper>
                    </div>
                    <button
                      type="submit"
                      class="btn btn-block btn-primary mt-2"
                    >
                      Unlock
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Lock Screen Ends-->
</div>
