import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CompanyRepository } from "./company.repository";
import { CompanyService } from "./services/company.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [CompanyRepository, CompanyService],
})
export class CompanyDomainModule {}
