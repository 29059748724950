import { SubscriptionUserType } from "../subscription-user-type/subscription-user-type";
import { User } from "../user/user";

export class SubscriptionUser {
  public id: string = "";
  public user_id: string = "";
  public active_since: string = "";
  public expiration_date: string = "";
  public subscription_type_id: string = "";

  public subscription_type?: SubscriptionUserType;
  public user?: User;
}
