import { Injectable } from "@angular/core";
import { ApiClient } from "@info-click/api-client";
import { CacheService } from "@info-click/cache";
import { Socket } from "ngx-socket-io";
import { Company } from "./company";
import { th } from "date-fns/locale";

@Injectable()
export class CompanyRepository {
  private uri = "companies";

  constructor(
    private api: ApiClient,
    private cache: CacheService,
    private socket: Socket
  ) {
    this.socket.fromEvent(`model-saved.${this.uri}`).subscribe(() => {
      this.cache.forget(`${this.uri}.search.*`);
    });
  }

  search(query?: object) {
    let observer = this.api.get(this.uri, query || {});
    return this.cache.putObservable(
      this.uri + ".search." + JSON.stringify(query),
      () => {
        return observer;
      }
    );
  }

  find(id: string) {
    return this.api.get(this.uri + "/" + id);
  }

  save(item: Company) {
    return item.id
      ? this.api.put(this.uri + "/" + item.id, item)
      : this.api.post(this.uri, item);
  }

  delete(id: string) {
    return this.api.delete(this.uri + "/" + id);
  }

  latest(id: string) {
    return this.api.patch(`${this.uri}/${id}/latest`);
  }
}
