import { NgModule } from "@angular/core";

import { CompanyRoutingModule } from "./company-routing.module";
import { InfoClickSharedModule } from "app/info-click/shared/info-click-shared.module";
import { CompanyDomainModule } from "app/info-click/domains/company/company-domain.module";
import { CompanyFormComponent } from "./company-form/company-form.component";
import { CompanyIndexComponent } from "./company-index/company-index.component";

@NgModule({
  declarations: [CompanyFormComponent, CompanyIndexComponent],
  imports: [CompanyRoutingModule, CompanyDomainModule, InfoClickSharedModule],
})
export class CompanyModule {}
