import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { SessionService } from "./session.service";
import { catchError } from "rxjs/operators";
@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        nonce: this.sessionService.getNonce(),
      },
    });

    return next.handle(req).pipe(
      catchError((err) => {
        this.sessionService.newNonce();
        if (err instanceof HttpErrorResponse) {
        }

        return throwError(err);
      })
    );
  }
}
