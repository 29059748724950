<app-page-title>
  <app-page-title-actions>
    <app-btn-list target="companies"></app-btn-list>
  </app-page-title-actions>
</app-page-title>

<div *ngIf="status === 'ready'">
  <app-form-panel (submit)="onSubmit()" [readonly]="readonly">
    <app-card>
      <div class="row">
        <div class="col-12 form-group">
          <label for="name" translate>Name</label>
          <input-wrapper [error]="errors.first('name')">
            <input
              id="name"
              name="name"
              [readonly]="readonly"
              [(ngModel)]="instance.name"
              class="form-control"
          /></input-wrapper>
        </div>
      </div>
    </app-card>
  </app-form-panel>
</div>
