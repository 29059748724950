import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthApiService } from "./auth-api.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth.interceptor";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AuthApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthDomainModule {}
