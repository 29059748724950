import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { JwtAuthGuard } from "@info-click/jwt-auth";
import { SessionInterceptor } from "./session.interceptor";
import { SessionService } from "./session.service";

@NgModule({
  declarations: [
    //
  ],
  imports: [CommonModule],
  providers: [
    SessionInterceptor,
    JwtAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    SessionService
  ],
  exports: [
    //
  ],
})
export class SessionModule {}
