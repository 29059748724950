<app-page-title>
  <app-page-title-actions>
    <app-filters-drawer-btn [drawer]="filtersDrawer"></app-filters-drawer-btn>
    <app-btn-add
      *userCan="'companies.create'"
      target="companies"
    ></app-btn-add>
  </app-page-title-actions>
</app-page-title>

<app-filters-drawer #filtersDrawer (change)="onChangeFilter()">
  <div class="row">
    <div class="col-md-4 col-sm-6">
      <label for="name" class="font-small-1 mb-0" translate>Name</label>
      <input
        class="form-control"
        id="name"
        name="name"
        (debounceKeyup)="filtersDrawer.onChange()"
        [(ngModel)]="filtersDrawer.filters.name"
      />
    </div>
  </div>
</app-filters-drawer>

<app-card *ngIf="status === 'ready'">
  <app-table-index
    [current-page]="pagination.meta.current_page"
    [last-page]="pagination.meta.last_page"
    [total]="pagination.meta.total"
    (change-page)="onChangePage($event)"
  >
    <thead>
      <tr>
        <th><span translate>Name</span></th>
        <th class="width-1-per">&nbsp;</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of pagination.data">
        <td>{{ item.name }}</td>
        <td class="col-action">
          <app-table-index-view-action
            *userCan="'companies.read'"
            [routerLink]="['/', 'companies', item.id]"
          ></app-table-index-view-action>

          <app-table-index-edit-action
            *userCan="'companies.update'"
            [routerLink]="['/', 'companies', item.id, 'edit']"
          ></app-table-index-edit-action>

          <app-table-index-delete-action
            *userCan="'companies.delete'"
            [label]="item.name"
            (delete)="onDeleteItem(item.id)"
          ></app-table-index-delete-action>
        </td>
      </tr>
    </tbody>
  </app-table-index>
</app-card>
