import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { HttpResourceResponse } from "../definitions/http-resource-response";

import { SettingRepository } from "../../domains/setting/setting.repository";

@Injectable({
  providedIn: "root",
})
export class SettingService implements OnInit, OnDestroy {
  loadSubscription: Subscription;
  public settings: { [key: string]: string };
  public logo: string;

  constructor(private repository: SettingRepository) {
    this.loadSettings();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.loadSubscription?.unsubscribe();
  }

  loadSettings(): Observable<void> {
    return new Observable<void>((observer) => {
      this.loadSubscription = this.repository.search().subscribe({
        next: (response: HttpResourceResponse<{ [key: string]: string }>) => {
          this.settings = response.body.data;
          observer.next();
        },
        error: (err) => observer.error(err),
      });
    });
  }

  get(key: string) {
    return this.settings[key] || null;
  }

  setAll(data: { [k: string]: any }) {
    this.settings = { ...data };
  }
  getAll(): { [key: string]: string } {
    return { ...this.settings };
  }

  getLogo() {
    return this.logo || "";
  }
}
