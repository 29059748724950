import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { RemoteValidationService } from "@info-click/remote-validation";
import { SubscriptionManager } from "@info-click/subscription-manager";
import { ToastrSubmitService } from "@info-click/toastr-submit";
import { Company } from "app/info-click/domains/company/company";
import { CompanyRepository } from "app/info-click/domains/company/company.repository";


import { HttpResourceResponse } from "app/info-click/shared/definitions/http-resource-response";

@Component({
  selector: "app-company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: ["./company-form.component.scss"],
})
@SubscriptionManager()
export class CompanyFormComponent implements OnInit {
  private addSubscription;

  public method: string;
  public readonly: boolean = false;
  public instance: Company = new Company();

  public status: "init" | "ready" = "init";

  constructor(
    private activatedRoute: ActivatedRoute,
    private repository: CompanyRepository,
    private cdr: ChangeDetectorRef,
    private toastrSubmit: ToastrSubmitService,
    private router: Router,
    public errors: RemoteValidationService
  ) {
    this.method = this.activatedRoute.snapshot.data.method || "create";
    this[this.method]();
  }

  ngOnInit(): void {}

  private loadInstance() {
    this.addSubscription(
      this.activatedRoute.params.subscribe((params) => {
        this.addSubscription(
          this.repository.find(params.id).subscribe({
            next: (response: HttpResourceResponse<Company>) => {
              Object.assign(this.instance, response.body.data);
              this.status = "ready";
              this.cdr.markForCheck();
            },
            error: (err) => console.log(err),
          })
        );
      })
    );
  }

  create() {
    this.status = "ready";
  }

  view() {
    this.readonly = true;
    this.loadInstance();
  }

  edit() {
    this.loadInstance();
  }

  onSubmit() {
    this.readonly = true;
    this.addSubscription(
      this.toastrSubmit.wrap(this.repository.save(this.instance)).subscribe({
        next: (response) => {
          Object.assign(this.instance, response.body.data);
          this.readonly = false;
          this.router
            .navigate(["/", "companies", this.instance.id, "edit"], {
              replaceUrl: true,
            })
            .then();
          this.cdr.markForCheck();
        },
        error: () => {
          this.cdr.markForCheck();
          this.readonly = false;
        },
      })
    );
  }
}
