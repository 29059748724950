import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, first, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { AuthApiService } from "./auth-api.service";
import { JwtAuthGuard } from "@info-click/jwt-auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshing: boolean = false;

  constructor(
    private authService: AuthApiService,
    private auth: JwtAuthGuard
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => {
        if (
          !this.refreshing &&
          this.auth.check() &&
          this.authService.isTimeToRefreshToken()
        ) {
          this.refreshing = true;
          this.authService.refresh().pipe(first()).subscribe({
            next: () => (this.refreshing = false),
          });
        }
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }
}
