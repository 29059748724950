import { Injectable } from "@angular/core";

@Injectable()
export class SessionService {
  constructor() {}

  public getNonce(): string {
    if (!localStorage.getItem("nonce")) {
      let nonce = (Math.random() + 1).toString(36).substring(7);
      localStorage.setItem("nonce", nonce);
    }

    return localStorage.getItem("nonce");
  }

  public newNonce(): string {
    let nonce = (Math.random() + 1).toString(36).substring(7);
    localStorage.setItem("nonce", nonce);

    return localStorage.getItem("nonce");
  }
}
