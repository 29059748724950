import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { APP_FULL_PAGE_ROUTES, APP_ROUTES } from "./config/routes";
import { JwtAuthMiddleware } from "@info-click/jwt-auth";
import { AuthorizationMiddleware } from "./info-click/shared/services/authorization.middleware";

const appRoutes: Routes = [
  {
    path: "",
    component: FullLayoutComponent,
    data: { title: "full Views" },
    children: APP_ROUTES,
    canActivate: [JwtAuthMiddleware, AuthorizationMiddleware],
    canActivateChild: [AuthorizationMiddleware],
  },
  {
    path: "",
    component: ContentLayoutComponent,
    data: { title: "full Views" },
    children: APP_FULL_PAGE_ROUTES,
    canActivate: [],
  },
  // {
  //   path: "**",
  //   redirectTo: "errors/404",
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
