import { Injectable } from "@angular/core";
import { APP } from "app/config/app";

@Injectable()
export class AttributeLangService {
  get(value: any) {
    if (value === null || value === undefined) {
      return "";
    }

    if (typeof value !== "object" || !value) {
      return "" + value;
    }

    let formattedValue = {};
    for (let key in value) {
      formattedValue[key.toLowerCase()] = value[key];
    }

    const defaultLang = APP.lang.toLowerCase();

    return (
      formattedValue[defaultLang] ||
      formattedValue["en"] ||
      formattedValue[Object.keys(formattedValue)[0]] ||
      ""
    );
  }
}
