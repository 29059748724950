import { RouteInfo } from "app/shared/vertical-menu/vertical-menu.metadata";
import { ROUTES as INFO_CLICK_ROUTES } from "../info-click/config/sidebar";

export const SIDEBAR = {
  collapsed: false,
  size: "sidebar-lg",
  backgroundColor: window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark-sidebar"
    : "light-sidebar",
  backgroundImage: true,
  backgroundImageURL: "assets/img/sidebar.jpg",
};

let infoClickRoutes = [...INFO_CLICK_ROUTES];

infoClickRoutes[0].submenu.push({
  path: "/connectors",
  title: "Connectors",
  permission: "connectors.*",
} as RouteInfo);

export const ROUTES: RouteInfo[] = [
  {
    path: "/",
    title: "Dashboard",
    icon: "ft-home",
  } as RouteInfo,
  ...infoClickRoutes,
  {
    path: "#",
    title: "Lists",
    icon: "ft-list",
    submenu: [
      {
        path: "/lines",
        title: "Lines",
        permission: "lines.*",
      } as RouteInfo,
      {
        path: "/languages",
        title: "Languages",
        permission: "languages.*",
      } as RouteInfo,
      {
        path: "/price-lists",
        title: "Price lists",
        permission: "price-lists.*",
      } as RouteInfo,
      {
        path: "/manufacturers",
        title: "Manufacturers",
        permission: "manufacturers.*",
      } as RouteInfo,
      {
        path: "/product-types",
        title: "Product types",
        permission: "product-types.*",
      } as RouteInfo,
      {
        path: "/channels",
        title: "Channels",
        permission: "channels.*",
      } as RouteInfo,
      {
        path: "/product-features",
        title: "Product features",
        permission: "product-features.*",
      } as RouteInfo,
      {
        path: "/markets",
        title: "Markets",
        permission: "markets.*",
      } as RouteInfo,
    ],
  } as RouteInfo,
  {
    path: "/seasons",
    title: "Seasons",
    icon: "ft-calendar",
    permission: "seasons.*",
  } as RouteInfo,
  {
    path: "/discounts",
    title: "Discounts",
    icon: "ft-percent",
    permission: "discounts.*",
  } as RouteInfo,
  {
    path: "/categories",
    title: "Categories",
    icon: "ft-folder",
    permission: "categories.*",
  } as RouteInfo,
  {
    path: "/product-attributes",
    title: "Product attributes",
    icon: "ft-tag",
    permission: "product-attributes.*",
  } as RouteInfo,
  {
    path: "/products",
    title: "Products",
    icon: "ft-package",
    permission: "products.*",
  } as RouteInfo,
  {
    path: "/syncs",
    title: "Syncs",
    icon: "ft-refresh-cw",
    permission: "syncs.*",
  } as RouteInfo,
];
